import { reporter } from '@shared/reporter';

import request from './request';

/**
 * Exchange a single token key for a fixed auth token from BE
 * @param {string} url - The BE endpoint URL.
 * @param {string} handShakeKey - Single use token key.
 *
 * @returns {string?} Fixed auth token
 */
export default async function exchangeSingleUseToken(url, handShakeKey) {
  try {
    const result = await request(url, {
      method: 'POST',
      body: JSON.stringify({
        singleUseToken: handShakeKey,
      }),
    });
    return result?.newToken ?? null;
  } catch (err) {
    reporter.error(err, { context: 'SingleUseToken handshake failed' });
    console.error('SingleUseToken handshake failed:\n', err);
    return null;
  }
}
