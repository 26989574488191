import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cc from 'classcat';

const ORIENTATIONS = {
  vertical: {
    direction: 'column',
    styles: {
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    },
  },
  horizontal: {
    direction: 'row',
    styles: {
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '',
    },
  },
};

const useStyles = makeStyles((theme) => ({
  stack: {
    justifyContent: ({ orientation, justify }) =>
      justify ?? ORIENTATIONS[orientation].styles.justifyContent,
    alignItems: ({ orientation, align }) =>
      align ?? ORIENTATIONS[orientation].styles.alignItems,
    height: ({ orientation }) => ORIENTATIONS[orientation].styles.height,
    display: 'flex',
    flexDirection: ({ orientation, direction }) =>
      direction ?? ORIENTATIONS[orientation].direction,
    flexWrap: ({ wrap }) => wrap,

    [theme.breakpoints.down('xs')]: {
      flexWrap: () => 'nowrap',
    },
  },
}));

function Stack({
  orientation = 'vertical',
  align,
  justify,
  wrap = 'wrap',
  direction,
  children,
  className,
  ...restProps
}) {
  const innerClasses = useStyles({ orientation, align, justify, wrap, direction });
  return (
    <div className={cc([innerClasses.stack, className])} {...restProps}>
      {children}
    </div>
  );
}

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.array,
  className: PropTypes.string,
  direction: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  wrap: PropTypes.string,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
};

export default Stack;
