import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import fetchIntercept from 'fetch-intercept';

import { actions } from '@shared/healthyAuth/slice';
import {
  handleRefreshToken,
  logoutAndInvalidate,
  getHeadersWithAuthToken,
} from '@shared/healthyAuth/utils';
import { network } from '@shared/utils/network';

/**
 * An interceptor hook around HealthyAuth.
 *
 * @see
 * - [`Axios Interceptors`](https://axios-http.com/docs/interceptors)
 * - [`fetch-intercept`](https://github.com/werk85/fetch-intercept)
 *
 * @param {Array<string>} apiUrls - Append authentication headers to the URLs listed in this list
 */
export default function useInterceptHealthyAuth({ apiUrls }) {
  const dispatch = useDispatch();

  function on401() {
    dispatch(actions.resetAuthData());
    logoutAndInvalidate();
  }

  // Intercepting Axios
  useEffect(() => {
    // Request
    const requestInterceptor = network.interceptors.request.use((config) => {
      const newConfig = { ...config };

      // Add authentication headers if the URL is listed as a Healthy API URL
      if (apiUrls?.length) {
        const isApiUrl = apiUrls.some((apiUrl) => config.url.includes(apiUrl));

        if (isApiUrl) {
          const authHeaders = network.shapeHeadersToObj(getHeadersWithAuthToken());

          newConfig.headers = {
            ...newConfig.headers,
            ...authHeaders,
          };
        }
      }

      return newConfig;
    });

    // Response
    const responseInterceptor = network.interceptors.response.use((config) => {
      if (config.headers['x-healthy-token']) {
        if (config.status === 401) {
          on401();
        }

        handleRefreshToken(config.headers);
      }

      return config;
    });

    return () => {
      network.interceptors.request.eject(requestInterceptor);
      network.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  // Intercepting Fetch
  useEffect(() => {
    const unregister = fetchIntercept.register({
      response(response) {
        if (response.status === 401 && response.url.includes('healthy.io')) {
          on401();
        }

        return response;
      },
    });

    return () => {
      unregister();
    };
  }, []);
}
