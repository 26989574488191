import ReactDOM from '@hot-loader/react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { install as installResizeObserver } from 'resize-observer';

import { LanguageFacilitator, initI18n } from '@shared/i18n';
import { AuthProvider } from '@shared/healthyAuth';
import tracking from '@shared/tracking';
import { reporter, ErrorBoundary } from '@shared/reporter';
import history from '@shared/redux/history';
import configureStore from '@shared/redux/configureStore';
import spotTheme from '@shared/styles/themes/spotTheme';
import HealthySnackbarProvider from '@shared/components/HealthySnackbarProvider';

import AuthenticatedApp from 'containers/App/authenticated-app-loadable';
import UnauthenticatedApp from 'containers/App/unauthenticated-app-loadable';

import ErrorBoundaryPage from 'components/ErrorBoundaryPage';
import Toaster from 'components/Toaster';

// Load the favicon
import '@shared/images/spot/wound_favicon.ico';

import '@shared/styles/global-styles.scss';
import './custom-styles.scss';

const domain = window.location.href;
const endsWithDotSlashRegex = /\.\/$/;
if (domain.match(endsWithDotSlashRegex)) {
  window.location.replace(domain.replace(endsWithDotSlashRegex, ''));
}

const store = configureStore();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

// Supporting graphs in older browsers
installResizeObserver();

initI18n();

tracking.initTracking({
  token: process.env.MIXPANEL_TOKEN,
  allowTracking:
    process.env.NODE_ENV === 'production' &&
    Boolean(process.env.HEALTHY_ENV === 'prod' || process.env.HEALTHY_ENV === 'staging'),
});

reporter.init({ dsn: process.env.SENTRY_DSN });

ReactDOM.render(
  <ThemeProvider theme={spotTheme}>
    <ErrorBoundary fallbackComponent={ErrorBoundaryPage}>
      <Provider store={store}>
        <HealthySnackbarProvider SnackComponent={Toaster} />
        <ConnectedRouter history={history}>
          <QueryClientProvider client={queryClient}>
            <LanguageFacilitator />
            <AuthProvider
              AuthenticatedApp={AuthenticatedApp}
              UnauthenticatedApp={UnauthenticatedApp}
              clientId={process.env.USER_MANAGEMENT_CLIENT_ID}
              redirectUri={window.location.origin}
              options={{
                userClaimsMultiRegion: {
                  uk: `${process.env.BASE_URL_UK}/userClaims`,
                  us: `${process.env.BASE_URL_US}/userClaims`,
                },
                singleUseTokenMultiRegion: {
                  uk: `${process.env.BASE_URL_UK}/validateSingleUseToken`,
                  us: `${process.env.BASE_URL_US}/validateSingleUseToken`,
                },
                externalAuthProviders: ['epic', 'mcb', 'cerner', 'dr_chrono'],
              }}
            />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById('app'),
);
