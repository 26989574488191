import PropTypes from 'prop-types';
import cc from 'classcat';

/**
 * Responsive Container
 * Uses a global `.container` class defined in `shared/styles/global-styles.scss`
 * which every app uses and can be overridden at the app level
 * Use this component to wrap layout with get a centered container with these widths based on BP
 * [min-width: 576px -> 570px]
 * [min-width: 768px -> 750px]
 * [min-width: 992px -> 980px]
 * [min-width: 1200px -> 1170px]
 *
 */

function ResponsiveContainer({ children, className }) {
  return <div className={cc(['container', className])}>{children}</div>;
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ResponsiveContainer;
