// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./almoni-neue-aaa-regular.otf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./almoni-neue-aaa-medium.otf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./almoni-neue-aaa-bold.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Almoni-normal */\n@font-face {\n  font-family: 'Almoni';\n  font-style: normal;\n  font-display: swap;\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n}\n/* Almoni-medium */\n@font-face {\n  font-family: 'Almoni';\n  font-style: normal;\n  font-display: swap;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n}\n/* Almoni-bold */\n@font-face {\n  font-family: 'Almoni';\n  font-style: normal;\n  font-display: swap;\n  font-weight: 500;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('opentype');\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
