import { createMuiTheme } from '@material-ui/core/styles';

import '@shared/fonts/gilroy/font-faces.scss';
import '@shared/fonts/almoni/font-faces.scss';

import spotTokens from '../tokens/spot';
// see options object structure
// https://material-ui.com/customization/themes/#theme-configuration-variables

export default createMuiTheme({
  breakpoints: {
    values: {
      xxs: 480,
      xs: 798,
      sm: 799,
      md: 1199,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: spotTokens.typography.fontFamily,
    useNextVariants: true,
  },
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.2,
    ...spotTokens.color,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: spotTokens.color.foreground.brand,
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: spotTokens.color.foreground.brand,
        },
      },
    },
    MuiLink: {
      root: {
        fontSize: '0.85rem',
        color: spotTokens.color.foreground.brand,
      },
    },
    MuiButton: {
      label: {
        fontWeight: 'bold',
        padding: `${spotTokens.spacing.base / 2}px ${spotTokens.spacing.base * 3}px`,
        whiteSpace: 'nowrap',
      },
      textPrimary: {
        color: spotTokens.color.foreground.brand,
        textTransform: 'none',
        borderRadius: 30,
        backgroundColor: 'transparent',
        '&:hover': {
          color: '#fff',
          backgroundColor: spotTokens.color.foreground.brand,
          '@media (hover: none)': {
            backgroundColor: spotTokens.color.foreground.brand,
          },
        },
        '&:disabled': {
          color: spotTokens.color.foreground.disabled,
        },
      },
      textSecondary: {
        color: spotTokens.color.foreground.text,
        backgroundColor: 'transparent',
        textTransform: 'none',
        borderRadius: 30,
        boxShadow: 'none',
        border: 'none',
        fontSize: '0.8rem',
        '&:hover': {
          boxShadow: 'none',
          border: 'none',
          backgroundColor: '#e8e8e8',
          '@media (hover: none)': {
            backgroundColor: '#e8e8e8',
          },
        },
        '&:disabled': {
          color: '#d2d2d2',
        },
      },
      containedPrimary: {
        color: '#fff',
        textTransform: 'none',
        borderRadius: 30,
        boxShadow: 'none',
        backgroundColor: spotTokens.color.foreground.brand,
        '&:hover': {
          backgroundColor: '#ff6761',
          boxShadow: 'none',
          '@media (hover: none)': {
            backgroundColor: '#ff6761',
          },
        },
        '&:disabled, &:disabled:hover': {
          backgroundColor: spotTokens.color.foreground.lightGrey,
          color: spotTokens.color.foreground.darkGrey,
        },
      },
      outlinedPrimary: {
        borderRadius: 30,
        textTransform: 'none',
        color: spotTokens.color.foreground.brand,
        border: 'none',
        boxShadow: `inset -2px 0 0 ${spotTokens.color.foreground.brand}, inset 0 -2px 0 ${spotTokens.color.foreground.brand}, inset 2px 0 0 ${spotTokens.color.foreground.brand}, inset 0 2px 0 ${spotTokens.color.foreground.brand}`,
        backgroundColor: 'transparent',
        '&:hover': {
          boxShadow: `inset -2px 0 0 ${spotTokens.color.foreground.brand}, inset 0 -2px 0 ${spotTokens.color.foreground.brand}, inset 2px 0 0 ${spotTokens.color.foreground.brand}, inset 0 2px 0 ${spotTokens.color.foreground.brand}`,
          border: 'none',
          backgroundColor: 'rgba(255, 155, 151, 0.15)',
          '@media (hover: none)': {
            backgroundColor: 'rgba(255, 155, 151, 0.15)',
          },
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          color: spotTokens.color.foreground.disabled,
          boxShadow: `inset -2px 0 0 ${spotTokens.color.foreground.disabled}, inset 0 -2px 0 ${spotTokens.color.foreground.disabled}, inset 2px 0 0 ${spotTokens.color.foreground.disabled}, inset 0 2px 0 ${spotTokens.color.foreground.disabled}`,
          border: 'none',
        },
      },
      startIcon: {
        height: 22,
        marginLeft: 0,
        marginRight: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      endIcon: {
        height: 22,
        marginRight: 0,
        marginLeft: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    MuiFab: {
      root: {
        width: spotTokens.spacing.base * 4,
        height: spotTokens.spacing.base * 4,
      },
      primary: {
        color: '#fff',
        boxShadow: 'none',
        backgroundColor: spotTokens.color.foreground.brand,
        '&:hover': {
          backgroundColor: '#ff6761',
          '@media (hover: none)': {
            backgroundColor: '#ff6761',
          },
        },
        '&:disabled': {
          backgroundColor: spotTokens.color.foreground.disabled,
          color: '#fff',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      secondary: {
        color: spotTokens.color.foreground.text,
        backgroundColor: '#fff',
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.35)',
        '&:hover': {
          backgroundColor: '#e8e8e8',
          '@media (hover: none)': {
            backgroundColor: '#e8e8e8',
          },
        },
        '&:disabled': {
          backgroundColor: '#fff',
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.25)',
          color: spotTokens.color.foreground.disabled,
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      extended: {
        color: spotTokens.color.foreground.text,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#e8e8e8',
          '@media (hover: none)': {
            backgroundColor: '#e8e8e8',
          },
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: spotTokens.color.foreground.text,
          opacity: 0.4,
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#fff',
        },
        '&$disabled': {
          cursor: 'not-allowed',
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: spotTokens.color.foreground.brand,
        '&$checked': {
          color: spotTokens.color.foreground.brand,
        },
      },
      checked: {},
    },
    MuiCheckbox: {
      colorSecondary: {
        color: spotTokens.color.foreground.brand,
        '&$checked': {
          color: spotTokens.color.foreground.brand,
        },
      },
      checked: {},
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          fontWeight: 'bold',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.7)',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: spotTokens.color.background.card,
      },
      colorSecondary: {
        color: spotTokens.color.foreground.brand,
      },
    },
    MuiSwitch: {
      colorPrimary: {
        '&$checked': {
          color: spotTokens.color.foreground.brand,
        },
        '&$checked + $track': {
          backgroundColor: spotTokens.color.foreground.brand,
        },
      },
    },
  },
});
