import mixpanel from 'mixpanel-browser';

import history from '@shared/redux/history';

let trackingAllowed = false;

/**
 * Initializes a new instance of the Mixpanel tracking object.
 *
 * @see
 * [mixpanel.init](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelinit)
 *
 * @param {object} options
 * @param {string} options.token - Mixpanel API token
 * @param {boolean} options.allowTracking - Set tracking on or off
 * @param {object} options.config - Config options to override
 */
function initTracking({ token, allowTracking, config = {} }) {
  trackingAllowed = allowTracking;

  if (trackingAllowed) mixpanel.init(token, config);
}

function identify(uniqueId) {
  if (trackingAllowed) mixpanel.identify(uniqueId);
}

function trackEvent({ eventName, props = {} }) {
  if (trackingAllowed) mixpanel.track(eventName, props);
}

function trackPageView(props = {}) {
  if (trackingAllowed)
    mixpanel.track('pageView', { from: history.location.pathname, ...props });
}

/**
 * Register a set of super properties, which are included with all events.
 * This will overwrite previous super property values.
 *
 * @see
 * [mixpanel.register](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelregister)
 *
 * @param {object} props - An associative object of properties to store
 */
function register(props = {}) {
  if (trackingAllowed) mixpanel.register(props);
}

/**
 * Delete a super property.
 *
 * @see
 * [mixpanel.unregister](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelunregister)
 *
 * @param {string} property - The name of the super property to remove
 */
function unregister(property) {
  if (trackingAllowed) mixpanel.unregister(property);
}

const tracking = {
  identify,
  initTracking,
  register,
  trackEvent,
  trackPageView,
  unregister,
};

export default tracking;
