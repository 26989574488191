import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tooltipPlacementBottom: {
    top: -16,
  },
}));

function WithTooltip({ children, title }) {
  const classes = useStyles();
  // Disabled elements do not fire events.
  // Users cannot hover or click them to trigger a popover / tooltip.
  // Wrapping in <span> and listening to the event fired on that instead.
  return (
    <Tooltip
      classes={{ tooltipPlacementBottom: classes.tooltipPlacementBottom }}
      title={title}
      arrow
    >
      <span>{children}</span>
    </Tooltip>
  );
}

WithTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default WithTooltip;
