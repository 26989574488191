import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

const ASSETS_FOLDER_PATH = '/assets/i18n';

const initI18n = () =>
  i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      backend: {
        loadPath: `${ASSETS_FOLDER_PATH}/{{lng}}.json`,
      },
      lng: 'en',
      react: {
        useSuspense: true,
      },
      fallbackLng: 'en',
      keySeparator: false,
      interpolation: {
        escapeValue: false, // react already safe from xss
      },
    });

export default initI18n;
