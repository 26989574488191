// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.b9k5Bf1F {\n  height: 2px;\n  width: 100%;\n  display: flex;\n  position: fixed;\n  top: 0;\n  z-index: 999;\n  color: #3f51b5;\n}[dir] div.b9k5Bf1F {\n  margin: 0;\n}\n\ndiv.b9k5Bf1F::before {\n  height: 2px;\n  width: 100%;\n  content: '';\n}\n\n[dir] div.b9k5Bf1F::before {\n  margin: 0;\n  background-color: currentColor;\n}\n\n[dir=ltr] div.b9k5Bf1F::before {\n  animation:  _2r-JURzy 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;\n}\n\n[dir=rtl] div.b9k5Bf1F::before {\n  animation:  _2cVQpFjA 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;\n}\n\n@keyframes _2r-JURzy {\n  0% {\n    margin-left: 0;\n    margin-right: 100%;\n  }\n\n  50% {\n    margin-left: 25%;\n    margin-right: 0%;\n  }\n\n  100% {\n    margin-left: 100%;\n    margin-right: 0;\n  }\n}\n\n@keyframes _2cVQpFjA {\n  0% {\n    margin-left: 0;\n    margin-right: 100%;\n  }\n\n  50% {\n    margin-left: 25%;\n    margin-right: 0%;\n  }\n\n  100% {\n    margin-left: 100%;\n    margin-right: 0;\n  }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "b9k5Bf1F",
	"running-progress-ltr": "_2r-JURzy",
	"running-progress-rtl": "_2cVQpFjA"
};
export default ___CSS_LOADER_EXPORT___;
