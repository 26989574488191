import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import MuiFab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

import WithTooltip from './WithTooltip';

/**
 * Based on [`Button`](https://material-ui.com/api/button/) and [`Fab`](https://material-ui.com/api/fab/) component in material-ui.
 *
 * The api is simplified to accommodate Healthy.io's [design system requirements](https://zpl.io/2Gdro6E).
 * All MUI props can be passed down to the button
 */

const Button = forwardRef(
  (
    {
      children,
      appearance = 'primary',
      isLoading,
      startIcon,
      endIcon,
      disabledAltText,
      ...restProps
    },
    ref,
  ) => {
    let variant;
    let color;
    let progressColor;
    let isIcon = false;
    // eslint-disable-next-line default-case
    switch (appearance) {
      case 'primary':
        variant = 'contained';
        color = 'primary';
        progressColor = 'primary';
        break;
      case 'primaryOutline':
        variant = 'outlined';
        progressColor = 'secondary';
        color = 'primary';
        break;
      case 'secondary':
        variant = 'text';
        color = 'primary';
        progressColor = 'secondary';
        break;
      case 'tertiary':
        variant = 'text';
        color = 'secondary';
        progressColor = 'inherit';
        break;
      case 'primaryIcon':
        variant = 'round';
        color = 'primary';
        progressColor = 'primary';
        isIcon = true;
        break;
      case 'secondaryIcon':
        variant = 'round';
        color = 'secondary';
        progressColor = 'secondary';
        isIcon = true;
        break;
      case 'tertiaryIcon':
        variant = 'extended';
        color = 'secondary';
        progressColor = 'inherit';
        isIcon = true;
        break;
    }

    const wrapWithTooltip = restProps.disabled && disabledAltText;

    const content = isLoading ? (
      <CircularProgress color={progressColor} size="1.5rem" />
    ) : (
      children
    );

    if (isIcon) {
      return wrapWithTooltip ? (
        <WithTooltip title={disabledAltText}>
          <MuiFab ref={ref} variant={variant} size="small" color={color} {...restProps}>
            {content}
          </MuiFab>
        </WithTooltip>
      ) : (
        <MuiFab ref={ref} variant={variant} size="small" color={color} {...restProps}>
          {content}
        </MuiFab>
      );
    }

    return wrapWithTooltip ? (
      <WithTooltip title={disabledAltText}>
        <MuiButton
          ref={ref}
          variant={variant}
          color={color}
          startIcon={isLoading ? null : startIcon}
          endIcon={isLoading ? null : endIcon}
          {...restProps}
        >
          {content}
        </MuiButton>
      </WithTooltip>
    ) : (
      <MuiButton
        ref={ref}
        variant={variant}
        color={color}
        startIcon={isLoading ? null : startIcon}
        endIcon={isLoading ? null : endIcon}
        {...restProps}
      >
        {content}
      </MuiButton>
    );
  },
);

Button.propTypes = {
  /** Sets the look and feel. Defaults to primary */
  appearance: PropTypes.oneOf([
    'primary',
    'primaryOutline',
    'secondary',
    'tertiary',
    'primaryIcon',
    'secondaryIcon',
    'tertiaryIcon',
  ]),
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  /**
   * Applicable when disabled is true. Display a tooltip with the passed string.
   * Useful when you want to explain the users why the action is disabled.
   */
  disabledAltText: PropTypes.string,
};

export default Button;
