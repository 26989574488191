const spotTokens = {
  color: {
    foreground: {
      brand: '#ff9b97',
      success: '#80c098',
      info: '#5f81f2',
      warning: '#ff6761',
      critical: '#d04e48',
      disabled: '#ffd9d8',
      text: '#4a4a4a',
      darkGrey: '#bfbfbf',
      lightGrey: '#e9e8e8',
      veryLightGrey: '#eeeeef',
    },
    background: {
      body: '#f9f9f9',
      card: '#ffffff',
      tag: '#dde8fd',
      grey: '#646464',
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#818181',
      disabled: '#bfbfbf',
    },
    strokes: {
      segmentation: '#f6c54b',
      segmentationActive: '#7fe300',
      convexHull: '#B97FFF',
      crossGreen: '#5af4d3',
      crossBlue: '#7fb1ff',
      depth: '#B97FFF',
      undermine: '#0368dc',
      tunnel: '#43C5A9',
    },
  },
  spacing: {
    base: 8,
  },
  typography: {
    fontFamily: [
      'Gilroy',
      'Almoni',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
};

export const colors = spotTokens.color;

export default spotTokens;
