import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spacer: {
    padding: ({ size }) => (size === 0 ? theme.spacing(0.5) : theme.spacing(size)),
  },
}));

function Spacer({ size = 3 }) {
  const classes = useStyles({ size });
  return <div className={classes.spacer} />;
}

Spacer.propTypes = {
  size: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
};

export default Spacer;
