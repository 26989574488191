import { useSelector, useDispatch } from 'react-redux';

import { actions } from './slice';
import { selectAuthState } from './selectors';
import {
  logoutAndInvalidate,
  getLoginUrl,
  getExternalPatientIdFromStorage,
  clearExternalPatientIdFromStorage,
} from './utils';

export default function useHealthyAuth() {
  const {
    user,
    additionalClaims,
    errorCode,
    isAuthenticated,
    clientId,
    redirectUri,
    enableDeepLinking,
  } = useSelector(selectAuthState);
  const dispatch = useDispatch();

  return {
    getLoginUrl: (email) =>
      getLoginUrl({ clientId, redirectUri, email, enableDeepLinking }),
    isAuthenticated,
    errorCode,
    userClaims: { ...user, ...additionalClaims },
    getExternalPatientIdFromStorage,
    clearExternalPatientIdFromStorage,
    forceLogout: () => {
      dispatch(actions.resetAuthData());
      logoutAndInvalidate();
    },
  };
}
