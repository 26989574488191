import { CHANGE_LANGUAGE_LOCALE, RESET_LANGUAGE_LOCALE } from './constants';

export function changeLanguageLocale(locale) {
  return {
    type: CHANGE_LANGUAGE_LOCALE,
    locale,
  };
}

export function resetLanguageLocale() {
  return {
    type: RESET_LANGUAGE_LOCALE,
  };
}
