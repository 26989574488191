// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./gilroy-regular.eot";
import ___CSS_LOADER_URL_IMPORT_1___ from "./gilroy-regular.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "./gilroy-regular.woff";
import ___CSS_LOADER_URL_IMPORT_3___ from "./gilroy-regular.ttf";
import ___CSS_LOADER_URL_IMPORT_4___ from "./gilroy-medium.eot";
import ___CSS_LOADER_URL_IMPORT_5___ from "./gilroy-medium.woff2";
import ___CSS_LOADER_URL_IMPORT_6___ from "./gilroy-medium.woff";
import ___CSS_LOADER_URL_IMPORT_7___ from "./gilroy-medium.ttf";
import ___CSS_LOADER_URL_IMPORT_8___ from "./gilroy-bold.eot";
import ___CSS_LOADER_URL_IMPORT_9___ from "./gilroy-bold.woff2";
import ___CSS_LOADER_URL_IMPORT_10___ from "./gilroy-bold.woff";
import ___CSS_LOADER_URL_IMPORT_11___ from "./gilroy-bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Gilroy-normal */\n@font-face {\n  font-family: 'Gilroy';\n  font-style: normal;\n  font-display: swap;\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  src:\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('truetype');\n}\n/* Gilroy-medium */\n@font-face {\n  font-family: 'Gilroy';\n  font-style: normal;\n  font-display: swap;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n  src:\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('embedded-opentype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('truetype');\n}\n/* Gilroy-bold */\n@font-face {\n  font-family: 'Gilroy';\n  font-style: normal;\n  font-display: swap;\n  font-weight: 500;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ");\n  src:\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format('embedded-opentype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_14___ + ") format('truetype');\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
