import 'whatwg-fetch';
import { getHeadersWithAuthToken, handleRefreshToken } from '../utils';

async function handleHttpResponse(response) {
  handleRefreshToken(response.headers);

  const contentType = response.headers.get('content-type');

  const responseBody = contentType.includes('application/json')
    ? await response.json()
    : await response.text();

  if (response.status >= 200 && response.status < 300) {
    return responseBody;
  }

  const errorMsg = responseBody?.message ?? response.statusText ?? response.status;
  const error = new Error(errorMsg);

  error.meta = {
    body: responseBody,
    message: responseBody?.message ?? JSON.stringify(responseBody),
    statusCode: response.status,
    statusText: response.statusText,
  };

  throw error;
}

export default async function request(url, options = { method: 'GET' }) {
  const headers = getHeadersWithAuthToken();
  headers.append('Content-Type', 'application/json');
  const response = await fetch(url, {
    headers,
    ...options,
  });
  return handleHttpResponse(response);
}
