import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@shared/components/Button';

import Card from 'components/Card';
import Spacer from 'components/Spacer';
import Stack from 'components/Stack';
import MessageSuccessIcon from 'components/Icons/MessageSuccessIcon';
import MessageErrorIcon from 'components/Icons/MessageErrorIcon';

function getColorByVariant(theme, variant) {
  switch (variant) {
    case 'success':
      return theme.palette.foreground.success;
    case 'info':
      return theme.palette.foreground.info;
    case 'error':
      return theme.palette.foreground.critical;
    case 'warning':
      return theme.palette.foreground.warning;
    default:
      return theme.palette.foreground.brand;
  }
}

function getIconByVariant(variant) {
  switch (variant) {
    case 'success':
      return MessageSuccessIcon;
    case 'error':
      return MessageErrorIcon;
    default:
      return MessageSuccessIcon;
  }
}

const useStyles = makeStyles((theme) => ({
  snackBar: {
    background: ({ variant }) => getColorByVariant(theme, variant),
    color: '#fff',
  },
}));

function Toaster({ message, onClick, variant }) {
  const classes = useStyles({ variant });
  const Icon = getIconByVariant(variant);
  return (
    <Card space={1} className={classes.snackBar} data-testid="user-notification">
      <Stack orientation="horizontal">
        <Spacer size={1} />
        <Icon />
        <Spacer size={1} />
        <span>{message}</span>
        <Spacer />
        <Button
          data-testid="close-user-notification"
          appearance="tertiaryIcon"
          onClick={onClick}
        >
          <CloseIcon />
        </Button>
        <Spacer size={1} />
      </Stack>
    </Card>
  );
}

Toaster.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'error', 'info', 'warning', 'default']),
};

export default Toaster;
