import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: ({ space }) => theme.spacing(space),
  },
}));

function Card({ children, space = 3, classes = {}, ...restProps }) {
  const innerClasses = useStyles({ space });
  return (
    <Paper classes={{ ...classes, root: innerClasses.root }} {...restProps}>
      {children}
    </Paper>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.number,
  classes: PropTypes.object,
};

export default Card;
