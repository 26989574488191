import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import cc from 'classcat';

import tracking from '@shared/tracking';
import ResponsiveContainer from '@shared/components/ResponsiveContainer';
import Button from '@shared/components/Button';
import { useTranslation, Trans } from '@shared/i18n';
import Loader from '@shared/components/Loader';

import Spacer from 'components/Spacer';
import Text from 'components/Text';
import Stack from 'components/Stack';

import ErrorImage from './error.png';
import CacheTableInstructions from './CacheTableInstructions';

const useStyles = makeStyles((theme) => ({
  mobileSizeOnly: {
    display: 'contents',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopSizeOnly: {
    display: 'contents',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  content: {
    flexBasis: '33%',
  },
  desc: {
    display: 'inline-block',
  },
  email: {
    color: 'inherit',
    fontWeight: 500,
  },
  expandTrigger: {
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  errorImage: {
    width: 290,

    [theme.breakpoints.down('sm')]: {
      width: 260,
    },
  },
  centerPosition: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  title: {
    fontSize: '3rem',
    textAlign: 'center',
    margin: 0,
    lineHeight: '1.1',
    color: theme.palette.foreground.text,
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  metadata: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  mobileInfo: {
    textAlign: 'center',
    padding: theme.spacing(0, 5),
    '& > a': {
      color: theme.palette.foreground.brand,
      textDecoration: 'underline',
      fontWeight: 500,
    },
  },
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

function ErrorBoundaryPage({ error, resetErrorBoundary }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    tracking.trackEvent({
      eventName: 'Error page',
      props: {
        attemptedUrl: window.location.pathname,
      },
    });
    if (expand) {
      tracking.trackEvent({
        eventName: 'Error page click did not help',
        props: {
          attemptedUrl: window.location.pathname,
        },
      });
    }
  }, [expand]);

  const handleExpand = () => {
    setExpand((prev) => !prev);
  };

  const handleRefresh = () => {
    resetErrorBoundary();
    tracking.trackEvent({
      eventName: 'Error page click refresh',
      props: {
        attemptedUrl: window.location.pathname,
      },
    });
  };

  const supportMailInfo = {
    to: process.env.CONTACT_US_EMAIL,
    subject: 'Bug Report spot-portal',
    body: {
      intro: '========== Auto generated ==========',
      error: error.toString(),
      browser: `userAgent: ${navigator.userAgent}`,
    },
  };

  // Show a loader instead of the error page until the refresh kicks in
  // See: shared/reporter/ErrorBoundary.js
  if (error.toString().includes('ChunkLoadError')) return <Loader />;

  const href = `mailto:${supportMailInfo.to}?subject=${supportMailInfo.subject}&body=%0D%0A%0D%0A%0D%0A${supportMailInfo.body.intro}%0D%0A${supportMailInfo.body.error}%0D%0A${supportMailInfo.body.browser}`;

  return (
    <ResponsiveContainer>
      <div className={classes.centerPosition}>
        <div className={classes.errorWrapper}>
          <Spacer />
          <Stack className={classes.content} align="center" justify="space-between">
            <h1 className={classes.title}>{t('errorPage.title')}</h1>
            <Text className={classes.metadata}>{t('errorPage.subtitle')}</Text>

            <div className={classes.mobileSizeOnly}>
              <Text className={classes.mobileInfo}>
                <Trans i18nKey="errorPage.help.info.mobile">
                  <a href="/">home</a>
                  <a href={href} target="_blank">
                    link
                  </a>
                </Trans>
              </Text>
            </div>

            <div className={classes.desktopSizeOnly}>
              <Button appearance="primary" onClick={handleRefresh}>
                {t('errorPage.primary.cta')}
              </Button>
              <Spacer size={1} />
              <Button
                disableRipple
                className={cc([classes.expandTrigger, classes.desktopAndMobileViewOnly])}
                appearance="tertiary"
                onClick={handleExpand}
              >
                {t('errorPage.secondary.cta')}
              </Button>

              <Collapse
                className={classes.metadata}
                in={expand}
                timeout={{
                  enter: 700,
                  exit: 500,
                }}
              >
                <Spacer size={1} />
                <Text>{t('errorPage.help.info')}</Text>
                <Spacer size={1} />
                <CacheTableInstructions />
                <Spacer size={1} />
                <Text>{t('errorPage.help.mail')}&nbsp;</Text>
                <a className={classes.email} href={href}>
                  <Text>{process.env.CONTACT_US_EMAIL}</Text>
                </a>
              </Collapse>
            </div>

            <Spacer />
          </Stack>
          <img src={ErrorImage} className={classes.errorImage} alt="error" />
        </div>
      </div>
    </ResponsiveContainer>
  );
}

ErrorBoundaryPage.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default ErrorBoundaryPage;
