import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 40,
    height: 40,
  },
}));

function MessageSuccessIcon() {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 49 49" className={classes.root}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M41.533 48.864l-34.273-.05c-4.015-.007-7.265-3.266-7.26-7.28L.05 7.26C.056 3.245 3.314-.005 7.33 0l34.275.05c4.015.005 7.265 3.264 7.259 7.28l-.05 34.275c-.007 4.015-3.266 7.265-7.28 7.259"
        />
        <path
          fill="#80BF98"
          d="M14.38 15.196h-.053c-1.447-.002-2.622 1.17-2.624 2.617L11.7 19.86c-.002 1.447 1.17 2.622 2.617 2.624h.052c1.448.001 2.622-1.17 2.624-2.617l.003-2.047c.002-1.447-1.169-2.622-2.616-2.624M37.977 22.14c-.666-.001-1.205-.542-1.204-1.207v-.705c.003-1.218-.987-2.211-2.206-2.213-1.287-.002-2.28.988-2.281 2.207l-.001.704c-.001.666-.542 1.205-1.208 1.204-.666-.001-1.205-.542-1.204-1.208v-.704c.004-2.548 2.08-4.618 4.629-4.615 2.617.004 4.687 2.08 4.684 4.629l-.001.704c-.001.666-.542 1.205-1.208 1.204M24.411 38.132c-5.693-.009-8.65-4.11-9.419-6.278-.223-.628.106-1.317.733-1.54.627-.223 1.315.105 1.54.73.075.206 1.778 4.668 7.15 4.676 5.433.008 7.146-4.605 7.163-4.65.225-.629.913-.958 1.542-.73.627.224.953.914.73 1.541-.776 2.165-3.746 6.26-9.439 6.251"
        />
      </g>
    </SvgIcon>
  );
}

export default MessageSuccessIcon;
