import produce from 'immer';

import { CHANGE_LANGUAGE_LOCALE, RESET_LANGUAGE_LOCALE } from './constants';

export const containerKey = 'Language';

export const initialState = {
  locale: 'en',
  supportedLanguages: ['en', 'he'],
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_LOCALE:
      draft.locale = action.locale;
      break;
    case RESET_LANGUAGE_LOCALE:
      draft.locale = initialState.locale;
      break;
  }
}, initialState);

export default reducer;
