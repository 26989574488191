import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 40,
    height: 40,
  },
}));

function MessageErrorIcon() {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 51" className={classes.root}>
      <defs>
        <path id="kuvbbpdzfa" d="M0.001 0.939L49.061 0.939 49.061 50 0.001 50z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-36 -150) translate(36 150) translate(0 .061)">
              <mask id="gn7duwmgeb" fill="#fff">
                <use xlinkHref="#kuvbbpdzfa" />
              </mask>
              <path
                fill="#FFF"
                d="M41.73 50L7.26 49.95C3.245 49.944-.005 46.685 0 42.67L.05 8.198C.056 4.184 3.316.934 7.33.938l34.472.05c4.015.007 7.265 3.267 7.259 7.282l-.05 34.47c-.006 4.016-3.265 7.266-7.28 7.26"
                mask="url(#gn7duwmgeb)"
              />
            </g>
            <path
              fill="#FF696D"
              d="M34.529 36.607c-.473-.001-.92-.28-1.112-.743-.095-.225-2.276-5.164-9.242-5.175-7.043-.01-9.235 5.09-9.256 5.143-.254.616-.96.908-1.576.653-.615-.255-.907-.96-.652-1.575.11-.272 2.848-6.645 11.486-6.632 8.64.013 11.358 6.393 11.469 6.665.253.616-.042 1.32-.658 1.573-.15.062-.306.09-.46.09M11.887 23.773c-.417-.001-.822-.217-1.045-.604-.332-.578-.133-1.315.444-1.647l4.224-2.431-4.217-2.443c-.576-.333-.773-1.07-.438-1.647.334-.577 1.07-.774 1.647-.44l6.025 3.49c.372.216.601.615.601 1.046-.001.43-.231.828-.604 1.043l-6.035 3.472c-.19.11-.398.16-.602.16M36.48 23.809c-.203-.001-.41-.053-.601-.163l-6.024-3.49c-.372-.215-.602-.614-.602-1.044 0-.432.232-.83.605-1.044l6.035-3.472c.578-.333 1.315-.134 1.647.443.332.578.132 1.315-.445 1.647l-4.223 2.43 4.216 2.444c.577.333.774 1.07.439 1.647-.224.387-.63.602-1.046.602"
              transform="translate(-36 -150) translate(36 150)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default MessageErrorIcon;
